@import "variables";
@import "mixins";

.model-add-user-body {
    padding: 20px 10px;
}

.add-user-heading {
    @include modelHeader;
}
.input-address-location{
    position: relative;
}
.add-user-input, .select-add-user-model, .model-select{
    border-radius: 5px;
    font-size: $fz13;
    // background-color: $inputFieldback_color;
}
// .user-table {
//     .react-select-container-list {
//         min-width: 120px;
//     }
// }
.country-code{
.react-select-container-list{
    min-width: 65px !important;
    margin: 0px;
    .react-select-list__control {
        height: 35px;
    }
}
}

.model-select{
    margin: 0 !important;
    width: 100% !important;
    .react-select-list__control {
        padding: 3px 10px 5px 8px;
        height: 35px ;
        .react-select-list__indicator {
            padding: 2px 5px !important;
            position: absolute;
            right: 0px;
            top: 4px;
        }
    }
}
.input-address-location .bi-geo-alt{
    position: absolute;
    font-weight: 600 !important;
    right: 25px;
    bottom: 7px;    
}

.google-maps-model .modal-dialog{
    max-width: 850px;    
}


.img-box {
    text-align: center;
    padding: 5px;
    background: $whiteColor;
    // border: 1px solid $modelhfborderColor;
    // box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: $selectInputShadow;
    object-fit: contain;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    // min-height: 235px;
    position: relative;
}

.img-file-name {
    position: relative;   
    text-align: center; 
    bottom: 0 !important;   
    font-size: $fz11; 
    color: $darkgrayColor;
}

.add-user-image {
    width: 100%;
    height: 100%;
    // background-color: azure;
    // border-radius: 20px;
    position: relative;
    object-fit: contain;
}

.btn-add-user-img {
    background-color: $primaryColor;
    color: $whiteColor;
    font-size: $fz14;
    position: absolute;
    bottom: 15px;
    left: 12px;
    text-align: center;
    padding: 5px 8px;
    width: auto;
    height: 35px;
    &:hover {
        background-color: $primaryColor;
        color: $whiteColor;
    }
    h6 {
        font-size: $fz13 !important;
        padding-left: 5px;
    }
}


.btn-inline-items {
    display: flex;
    position: relative;
    align-items: center;    
}
#countryCodeSelect{
    padding: 6px 2px;
    padding-inline: 4px;
    background-position: right 3px center;
}

.input-mobile-field .country-code {
    width: 75px;
    margin-right: 8px
}

.add-user .btn,
.add-user .form-control {
    box-shadow: none;
}

.enable-radio-client {
    position: relative;
    vertical-align: middle;
    align-items: center;
}

.enable-radio-status {
    margin-left: 10px;
}

.google-address-ok{
    position: absolute;
    right: 40px;
    background-color: green;
    color: white;
    border: none;
    padding: 3px 10px;
    &:hover{
        background-color: darkgreen;
    }
}

.add-user-main-logo-image .add-user-images {
    height: 200px;
    left: 50%;
    max-width: 200px;
    object-fit: fill;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.add-user-main-logo-image {
    background-image: radial-gradient(#fff, #fff, #e4e4e4 70%);
    border-radius: 4px;
    max-height: 220px;
    min-height: 220px;
    padding: 10px;
    position: relative;
    width: 100%;
}

.add-user-image-container-div {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
}

.add-user-title h4 {
    color: $primaryColor;
    font-weight: 500;
    margin-left: 80px;
    // font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-family: "Roboto", sans-serif;
    ;
}

.modal-fullscreen-xxl-down {
    max-width: 100% !important;
    margin: 0 !important;
}

.add-user-container {
    padding: 0px 80px;
}

.upld-n-templt-downld {
    margin: auto 25% auto 5%;
}

