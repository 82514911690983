@import "variables";

#header {
    position: absolute;

    .pro-sidebar {
        height: 100vh;
        width: 100%;
        min-width: 100% !important;
    }

    .pro-sidebar-inner .pro-sidebar-layout {

        ul {
            padding: 0px;
        }
    }
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none !important;
}

.side_bar_header_main {
    width: 100%;
    height: 60px;
    background-color: $whiteColor !important;
    align-items: center !important;
    z-index: 10;
    padding: 8px 6px;
}


.salesbee-logo {
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 5px;
}

.profile-image-sidebar-big {
    position: relative;
    background-color: $whiteColor;
    display: block;
    padding: 6px;

    .profile-image {
        img {
            width: 60px;
            height: 60px;
            border: 3px solid $secondaryColor;
            // border: linear-gradient(to right, #FFB900, #04A96D);
            border-radius: 15px;
            position: relative;
            left: 60px;
            vertical-align: middle;
        }
    }
}

.travelize_logo {
    display: block !important;
    text-decoration: none;
    color: $whiteColor;
    font-size: $fz36;
    font-weight: $fwt400;
    word-spacing: 4px;
    // font-family: 'Saira Stencil One', cursive;    
    font-family: 'Roboto', sans-serif;
    // max-width: 215px;
    width: 100%;
    height: 100%;

    &:hover {
        color: $whiteColor;
    }
}

.travelize-logo-pro {
    font-size: $fz12;
    color: $whiteColor;
    font-weight: $fwt300;
    // font-family: 'Koulen', cursive;
    font-family: 'Roboto', sans-serif;
    vertical-align: super;
    top: -0.2rem;
    right: 0.5rem;
}

.profile-data-name {
    text-align: center;
    font-weight: $fwt600;
    color: $blackColor;
    padding-top: 5px;
    font-size: 15px;
}

.profile-data-title {
    text-align: center;
    font-size: $fz13;
    color: $primaryColor;
    font-weight: 800;
}

.fa-icon-div {
    width: 20px;
}

.sub-icon-div {
    width: 18px;
    display: flex;
}

.profile-controls {
    position: relative;

    .beta-tag-inside {
        padding: 10px 20px;
        background: $secondaryColor;
        position: absolute;
        top: -25px;
        left: -54px;
        transform: rotate(-45deg);
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        h6 {
            color: $whiteColor;
        }
    }
}

.nav-link {
    margin-left: 0px;
}

.sidebar_content {
    background-color: $whiteColor;
    overflow-y: auto;
    padding-bottom: 15px;

    .expanded-nav-bar {
        li {
            list-style: none;
            padding: 10px;

            &:hover a {
                color: $whiteColor;
            }

            &:focus a {
                color: $whiteColor;
            }
        }

        a {
            text-decoration: none;
            color: $primaryText_Color;
        }

        .xn-text {
            color: $primaryText_Color;
            ;
            margin-left: 8px;
            font-size: $fz14;
            font-weight: $fwt400;
        }
    }
}

.sidebar-item .nav-link {
    padding: 0px !important;
}

.drop-main:hover {
    color: $primaryColor !important;
    // background-image: linear-gradient(45deg, #04A96D, #5aa904);
    // background-color: $primaryColor !important;
    // border-left: 4px solid $secondaryColor !important;
}

.drop-main:hover .sidebar-title {
    color: $primaryColor !important;
}

.nav-link-item {
    align-items: center;
    min-width: 185px;
}

.expanded-nav-bar {

    .nav-link-item.active,
    .sidebar-item.plain.active {
        position: relative;
        color: $primaryColor !important;
        // background-color: $primaryColor !important;
        background-image: $sidebarActive;
        // border-left: 4px solid $secondaryColor !important;
    }

    .sidebar-item.plain:hover {
        position: relative;
        color: $primaryColor !important;
    }

    .nav-link-item.active::before,
    .sidebar-item.plain.active::before {
        content: "";
        height: 30px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 3px;
        background-color: $primaryColor;
        position: absolute;
        left: 0;
        border-radius: 0 3px 3px 0;
    }


    .nav-link-item.active .sidebar-title {
        color: $primaryColor;
    }
}

// .drop-down-item {
//     padding: 8px 10px 8px 30px
// }

// .sidebar-item .sidebar-content.sub-active .nav-link-item {
// background-color: #FFB9000;
// background-image: linear-gradient(45deg, #ffb900, #b1bb17);
// border-left: 4px solid #04A96D;
// }

.sidebar-item {
    cursor: pointer;

    &:hover {
        cursor: pointer;
    }

    .sidebar-content {
        height: 0;
        overflow: hidden;

        .nav-link-item:hover {
            color: $primaryColor;
        }

        &.sub-active .nav-link-item {
            color: $primaryColor;

            .xn-text {
                color: $primaryColor;
                font-weight: 500;
            }
        }

        .nav-link-item .bi {
            font-size: $fz10;
        }
    }

    &.open .sidebar-content {

        height: auto;
        vertical-align: middle;
        // padding: 8px 10px 8px 30px;

        // &:hover {

        //     .nav-link-item {
        //         color: white;
        //         padding: 8px 10px 8px 30px;
        //     }
        // }

        .nav-link-item .xn-text {
            font-size: $fz14;
        }

        .nav-link-item {
            padding: 8px 10px 8px 30px;

        }
    }



    &.open {
        .nav-link-item.active .sidebar-title .toggle-btn {
            cursor: pointer;
            transition: transform .1s;
        }

        .nav-link-item.active>.sidebar-title .toggle-btn {
            transform: rotate(90deg);
        }

        .active .sidebar-title {
            color: $primaryColor;
        }
    }

    &.plain {
        &.active a {
            color: $whiteColor;
            text-decoration: none;
        }

        i {
            display: inline-block;
            width: 1.7em;
        }
    }
}

.sidebar-title {
    align-items: center;
    color: $veryDarkGrayishBlue;
    display: flex;

    &:hover {
        color: $primaryColor;
    }

    .bi-chevron-right.toggle-btn {
        margin-left: auto;
        margin-right: 10px;
    }
}




/* COLLAPASED NAV BAR START */

.collapsed-nav-bar {
    overflow-y: hidden;
    padding: 10px;

    li {
        list-style: none;
        text-align: center;

        // &:hover {
        //     background-color: $blackColor;
        // }

        >a {
            text-decoration: none;
            color: $nav_link_Textcolor;
        }

        &:hover>a {
            color: $primaryColor;
        }

        >a.active {
            color: $primaryColor;
        }
    }

    .fa {
        margin: 10px 0px;
        font-size: $fz18;
        padding: 5px;
        font-weight: $fwt600;
    }
}
.logotext {
    width: 100%;
    height: 60%;
}
.profile-mini {
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0px 12px;

    .profile-image {
        padding: 2px;
    }
}

.profile-image img {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 10px;
    padding: 0px;
    background-color: $whiteColor;
    object-fit: contain;
}

/* COLLAPASED NAV BAR END */


/* width */
.sidenavbar-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    // background-color: $secondaryColor !important;
    border-radius: 6px !important;
}

/* Handle */
.sidenavbar-scroll::-webkit-scrollbar-thumb {
    background: $secondaryColor;
    border-radius: 10px !important;
}

/* Handle on hover */
.sidenavbar-scroll:-webkit-scrollbar-thumb:hover {
    // background: rgb(168, 168, 168);
    background: $grayColor;
}

.setting-image {
    //  position: fixed;
    //  z-index: 2;
    //  bottom: 5%;
    //  right: 5%;
    //  background-color: #fff;
    //  border-radius: 50%;
    //  padding: 10px;
    //  width: 50px;
    //  height: 50px;
    cursor: pointer;
}

.setting-image img {
    // width: 100%;
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @-ms-keyframes spin {
        from {
            -ms-transform: rotate(0deg);
        }

        to {
            -ms-transform: rotate(360deg);
        }
    }

    @-moz-keyframes spin {
        from {
            -moz-transform: rotate(0deg);
        }

        to {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        from {
            -webkit-transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}


/* New CSS */
.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    color: $veryDarkGrayishBlue !important;
}

.pro-sidebar .pro-menu {
    padding: 0px;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover,
.pro-item-content:hover {
    color: $primaryColor !important;
}

.pro-sidebar .pro-menu li.pro-menu-item.sidebar-item.plain.active,
.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item.active {
    color: $primaryColor !important;
    background-image: linear-gradient(to right, rgba(66, 138, 121, 0.1) 30%, transparent 100%);
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    padding: 0px !important;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
    background-color: $whiteColor !important;
}


.powered-by-div{
    text-align: center;
    margin-top: 25px;
}
.powered-by-div span{
    color: $successColor;
    font-size: 10px;
}

/* New CSS */