@import "variables";
@import "mixins";

.page-content {
    @include pageContentProperty;
}

.checkin-map-table {
    @include tableProperty;
}

.page-title {
    @include pageTitle;
}

.trackers-list-thead {
    @include tableHeadProperty;
}

.tracker-tabel-scroll {
    margin: 10px;
    overflow-x: hidden;
}

.tracker-meetings-tabel-scroll{
    margin: 10px 5px 0px 5px;
    overflow-x: hidden;
}

.track-list-name {
    position: relative;
    @include tableDataImgTxt;

    h6 {
        font-size: $tableData_headTextSize;
    }
}

.table-data-track-imgages {
    @include userImgProperty(35px);
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
}

.tracker-details-table {
    height: auto;
}

.checkin-details-table {
    height: auto;
    .checkin-details-column{
        height: calc(100vh - 120px);
        position: relative;
        overflow-x: hidden;
        padding: 5px;
        .userschekin-location{
            padding: 3px 5px;
        }
    }
}

.active-user-image {
    position: relative;

    .blink-effect-dashboard {
        border-radius: 50%;
        position: absolute;
        left: 65%;
        bottom: 0%;
        animation: blink 1s infinite;
        border: 2px solid transparent;
        animation: blink 1s infinite;
    }
}

.tracker-page-google-map {
    position: relative;

    .google-map-box {
        margin-top: 10px;
        position: relative;
        top: 0;
        left: 0;
        width: 100% !important;
        text-align: center;
        height: auto;

        h5 {
            margin-bottom: 0px;
        }
        .google-component{
            height: 450px !important;
        }
    }
}

.check-in-status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    cursor: pointer;

}

.blink-effect {
    border-radius: 50%;
    position: absolute;
    left: 55%;
    bottom: 0%;
    animation: blink 1s infinite;
    border: 2px solid transparent;
    animation: blink 1s infinite;
}



.status-inactive {
    background-color: $dangerColor;
}

.status-active {
    background-color: $successColor;
}

.custom-marker {
    position: relative;
}

.marker-label {
    border-radius: '50%';
    background-color: $dangerColor; // Customize the color as needed
    width: '32px'; // Adjust the size as needed
    height: '32px';
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    cursor: 'pointer'
}

.empty-map {
    // opacity: 0.6;
    position: relative;
    .overlay-div{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-color: $lightGrayBlack;
        .over-lay-text-div{
            padding: 20px;
            border-radius: 10px;
            background-color: $whiteColor;
        }
    }
    .gm-style .place-card-large{
        display: none !important;
    }
}