@import "variables";
@import "mixins";


.routes-table {
    @include tableProperty;
}

.routes-scrollbar {
    height: calc(100vh - 200px);
    margin: 10px;
}

.routes-thead {
    @include tableHeadProperty;
}

.routes-table-data {
    .action-part{        
        max-width: 100px;
        max-height: 30px;
        width: 100px;
        height: 30px;
    }
    .edit-route{
        padding-right: 15px; 
        cursor: pointer;       
    }
        .client-asign-btn{
            margin-top: 3px;
            padding: 3px;
            .material-symbols-outlined {
                font-size: 1.5rem !important;
            }
        }
    .pause-play-btn {
        width: 30px;
        font-size: $fz18;        

        .fa-pause-circle {            
            // margin: 5px;
            color: $dangerColor;
            background-color: $lightRed;
            animation-name: glow;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }

        .fa-play-circle {          
            // margin: 5px;
            color: $primaryColor;
            background-color: $palelightGreen;
            animation-name: glow;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }

        @keyframes glow {
            0% {
                padding: 0px 0px;
                border-radius: 50%;               
            }

            25% {
                padding: 1px 2px;
                border-radius: 50%;               
            }

            50% {
                padding: 3px 4px;
                border-radius: 50%;              
            }

            75% {
                padding: 4px 5px;
                border-radius: 50%;               
            }

            100% {
                padding: 5px 6px;
                border-radius: 50%;               
            }
        }
    }
}

.routes-plan-table-data {
    tbody {
        tr {
            cursor: pointer;

            &:hover {
                background-color: rgba(124, 229, 169, 0.0823529412) !important;
            }
        }

        td {
            .status {
                padding: 4px 8px;
                border-radius: 6px;
                // &.Completed, .completed {
                //     color: $primaryColor !important;
                //     background-color: $paleGreen;
                //     border: 1px solid $primaryColor;
                // }

                // &.Pending, .pending {
                //     color: $danderColor !important;
                //     background-color: $lightRed;
                //     border: 1px solid $danderColor;
                // }
               &.Planned, .planned{
                    color: $secondaryColor;
                    background-color: $paleYellow;
                    border: 1px solid $secondaryColor;
               }
            }
        }
    }
}

.google-maps-route-model {
    .route-map-table {
        padding: 10px 15px;
        border-bottom: 2px dotted $grayColor;

        .fa {
            .fa {
                font-size: $fz15;
            }
        }
    }
}

.google-maps-route-model{
    .modal-dialog{
        max-width: 100%;
        margin: 0 auto;
    }
}

.btn-add-routes {
    @include buttonProperty($primaryColor, 30px, auto, 0, 14px, $whiteColor);
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: $whiteColor;
    // flex: none;
    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $primaryColor;
    }

    &:focus {
        box-shadow: none;
    }

    p {
        font-size: $fz14;
        color: $whiteColor;
    }
}


.routes-edit-link {
    color: $primaryColor;
    font-size: $fz13;
    padding: 0px 5px;
    text-align: left;
    &:hover {
        color: $primaryColor;
    }
}


/* ADD & EDIT CLIENT MODEL START */
.add-basefare-input {
    border-radius: 5px;
    font-size: $fz13;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.add-edit-basefare-model {
    .modal-content {
        width: 80% !important;
        margin-left: 50px;
    }

    .add-edit-basefare {
        padding: 10px 20px;

        .btns-save-cancel {
            display: inline-flex;
            margin-bottom: 10px;
            align-items: center;
            text-align: center;
            justify-content: center;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-save-up {
            background-color: $primaryColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            height: 30px;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-cancel {
            background-color: $grayColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            margin-right: 10px;
            height: 30px;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }
    }
}

.add-edit-basefare-heading {
    @include modelHeader;
}



.add-edit-model.clients-route-visit .modal-content {
    width: 100% !important;
    margin: auto;
}

.add-rote-model {
    height: 500px;
    overflow-x: hidden;
}

.client-list-with-date-time {
    // padding: 5px 10px;
    // box-shadow: $card_box_shadow;
    &.edit-part{
        height: 300px;
        overflow-x: hidden;
    }

    .display-column-wise {
        display: flex;
        align-items: center;
        padding: 10px;

        .clients-data {
            width: 100%;

            .clientn-datetime-icon {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 15px;
                box-shadow: $card_box_shadow;
                margin: 10px auto;
                border-radius: 15px;

                .clients-details {
                    padding: 10px 10px 10px 0px;
                    max-width: 300px;
                }

                .set-date-time-icon {
                    text-align: right;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    .remove-icon{
                        .material-symbols-outlined{
                            color: $grayColor !important;
                            font-size: $fz20 !important;
                            margin-bottom: 5px;
                            cursor: pointer;
                        }
                    }

                    i {
                        font-size: $fz25;
                        color: $primaryColor;
                        cursor: pointer;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .display-datentime-field {
        margin: 15px auto;

        .date-n-time-part {
            text-align: center;

            .set-visit-date {
                padding: 5px;
            }

            .set-visit-time {
                padding: 5px;
            }
        }
    }
}

/* SCROLL BAR CSS with Styling START */
/* width */
.routes-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.routes-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.routes-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.routes-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

.route-deviation-link a{
    color: $primaryColor;
}


// /* SCROLL BAR CSS with Styling END 