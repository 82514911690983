@import "variables";
@import "mixins";

.clients-table {
    @include tableProperty;
}

.clients-scroll {
    height: calc(100vh - 210px);
    margin: 10px;
}

.clients-list-thead {
    @include tableHeadProperty;
}

.clients-table{
    .client-search-input{
        position: relative;
        .fa-search {
            position: absolute;
            top: 10px;
            right: 10px;
            color: lightgray;
            opacity: 0.8;
        }
        input[type=search] {
            width: auto;
            padding-right: 25px;
        }
    }
} 
.input-search-client{
    width: 100% !important;
    line-height: 16px !important;
}

.main-logo-image {
    position: relative;
    display: flex;
    width: 100% !important;
    align-items: center;
    justify-items: center;
    padding: 10px;
    border-radius: 4px;
    min-height: 180px;
    max-height: 180px;
    background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 70%);
    .product-images {
        width: 100%;
        height: 155px;
        object-fit: fill;
    }
}


.btn-add-client {
    @include buttonProperty($blackColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;
    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $blackColor;
    }
    &:focus {
        box-shadow: none;
    }
    h6 {
        font-size: $fz14;
        padding-left: 5px;
    }
}

.client-list-row-data {
    // position: relative;
    .client-edit-link {
        color: $primaryColor;
        font-size: $fz13;        
        padding: 5px;
        line-height: 1;
        // i{
        //     padding-right: 3px;
        // }
    }
    .client-history-link{
        color: $primaryColor;
        font-size: $fz13;
        padding: 5px;
        line-height: 1;
    }
    .client-asign-btn{
        padding: 8px 2px 2px 2px;
        .material-symbols-outlined {
                color: $primaryColor !important;
                font-size: 22px !important;
            }               
    }
    .client-location {
        max-width: 250px;        
    }
    .client-email, .client-tagged {
        color: $secondaryColor;
    }
} 

.re-tag-count{
    color: $secondaryColor;
    .fa-thumb-tack{
        font-size: $fz10;
    }
}

.view-mapped-user{
    .fa{
        font-size: $fz14;
        color: $primaryColor;
        padding-left: 5px;
    }
}

.mappedto-users-model{
    .modal-content{
        width: 550px !important;
    }
}

/* ADD & EDIT CLIENT MODEL START */
.add-edit-clients-model .modal-content {
    width: 95% !important;    
    // margin-left: 50px;
}

.add-edit-client {
    padding: 10px;
    &.edit-client{
        padding: 10px;
    }
    .form-select{
        padding: .375rem 2.25rem .375rem .75rem;
    }
    .location{
        min-height: 60px;
    }
}
.enable-status{
    margin-left: 10px;
}
.add-edit-client-heading {
    @include modelHeader
}

.enable-radio-client {
    position: relative;
    vertical-align: middle;
    align-items: center;
}

.enable-radio-status {
    margin-left: 10px;
}

.btns-save-cancel {
    @include modelFooter;
}

.client-location-by-map{
    position: relative;
    .bi-geo-alt{
        position: absolute;
        right: 20px;
        bottom: 5px;
    }
}

/* SCROLL BAR CSS with Styling START */
/* width */
.clients-scroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.clients-scroll::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.clients-scroll::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.clients-scroll:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

.company-name-head {
    min-width: 230px !important;
}


.client-table td:first-child{
    position: sticky;
    left: 0;
    // z-index: 1;
}
.client-table th:first-child{
    top: 0 !important;
    z-index: 2;
    position: sticky;
    left: 0;
}

.customer-dropdown-cont {
    padding: 10px 0px;
    .customer-dropdown-item {
        cursor: pointer;
        padding: 5px 15px;
        span{
            font-size: 20px;
        }
        &:hover {
            color: $primaryColor;
        }
        &.active {
            color: $primaryColor;
        }
    }
}
/* SCROLL BAR CSS with Styling END */

// Css for card design
.company-card-view {
    position: relative;
    width: 20%;
}
.company-card-container {
    position: relative;
	min-height: 240px;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out !important;
    
    &:hover {
        box-shadow: $card_shadow_hover;
        transform: scale(1.03);
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out !important;

        .side {
            z-index: 9999;
            overflow: hidden;
            // &:hover{
                .dot{
                    display: flex !important;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    border-radius: 6px;
                    background-color: $iconBackground;
                    padding: 4px;
                    bottom: 2px;
                    right: 5px;
                    box-shadow: $card_BoxShadow !important;
                    cursor: pointer;
                    .fa {
                        color: $primaryColor;
                        font-weight: 900;
                        font-size: 0.8rem;
                    }
                }
    
                .adddress-tooltip-div {
                    display: inline-flex;
                    padding: 0 10px;
                    cursor: pointer;
                    .address-tool {
                        padding: 1px 5px;
                        border-radius: 50%;
                        color: $whiteColor !important;
                        background-color: $secondaryColor;
                    }
                }
            // }
        }
    }

    .main-side {
        position: relative;
        width: 100%;
        height: 200px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .footer-side {
        position: relative;
        width: 100%;
        height: 40px;
        color: #000;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
            display: flex;
            align-items: center;
            .icon-div {
                padding: 0 15px;
                width: 70%;
                display: flex;
                .fa {
                    cursor: pointer;
                    padding-right: 10px;
                    color: $primaryColor;
                }
                // justify-content: space-between;
            }
            
            .view-profile {
                display: flex;
                justify-content: flex-end;
                padding: 0 10px;
                // text-align: right;
                width: 40%;
                .text-bnt {
                    padding: 0 5px;
                    cursor: pointer;
                    color: $primaryColor;
                    font-weight: 600;
                    &:hover {
                        color: $secondaryColor !important;
                    }
                }
            }
    }
    .side {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 10px;
        color: #000;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .dot, .adddress-tooltip-div {
            display: none;
        }
        
        // &:hover{
        //     .dot{
        //         display: flex;
        //         width: 20px;
        //         height: 20px;
        //         position: absolute;
        //         border-radius: 6px;
        //         background-color: $iconBackground;
        //         padding: 4px;
        //         bottom: 2px;
        //         right: 5px;
        //         box-shadow: $card_BoxShadow !important;
        //         cursor: pointer;
        //         .fa {
        //             color: $primaryColor;
        //             font-weight: 900;
        //             font-size: 0.8rem;
        //         }
        //     }

        //     .adddress-tooltip-div {
        //         display: inline-flex;
        //         padding: 0 10px;
        //         cursor: pointer;
        //         .address-tool {
        //             padding: 1px 5px;
        //             border-radius: 50%;
        //             color: $whiteColor !important;
        //             background-color: $secondaryColor;
        //         }
        //     }
        // }
        
        .content {
            width: 100%;
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // transform: translatez(50px) scale(0.92);
            // line-height: 1.5em;
            position: relative;
            
            .company-data {
                padding: 5px;
                width: 25%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .re-tag{
                    padding: 10px 0 5px;
                    color: $tableHead_Color;
                    font-weight: 600;
                }
                
                .company-logo-img{
                    height: 70px;
                    width: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 25px;
                    font-weight: 800;
                    color: $whiteColor;
                    // &.active {
                    //     border: 2px solid $compStatusColor;
                    //     color: $whiteColor;
                    // }
                    // &.inactive {
                    //     border: 2px solid $cancStatusColor;
                    //     color: $textHeadColor;
                    // }
                }
            }
            
            .card-devider-div {
                padding: 5px;
                height: 100%;
                display: flex;
                align-items: center;
                .card-devider{
                    width: 2px;
                    height: 75%;
                    background-color: $primaryColor;
                }
            }
            .contact-person-data {
                position: relative;
                padding: 5px 10px 5px 20px;
                width: 75%;
                .fa {
                    color: $paleGray;
                    font-size: 13px;
                }
            }
        }
        .content h1 {
            position: relative;
        }
        .content p {
            margin-top: 50px;
            line-height: 2em;
        }
        .content h1:before {
            content: "";
            position: absolute;
            bottom: -20px;
            height: 3px;
            background-color: #3e3;
            width: 70px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .front {
        z-index: 2;
        background-size: 100vh;
        background-size: cover;
        background-color: #fff;
        box-shadow: 0px 0px 6px -2.9px $borderColor;
        // background-image: url(https://userscontent2.emaze.com/images/f9538183-0ff9-478f-b964-c8ab90421e3b/3d28e192fda5c17250f96a2779c84475.jpg);
    }
    .back {
        background-color: #fff;
        transform: rotateY(180deg);
        z-index: 0;
        padding-top: 10px;
        box-shadow: 0px 0px 6px -2.9px $borderColor;
        // background-image: url(https://userscontent2.emaze.com/images/f9538183-0ff9-478f-b964-c8ab90421e3b/3d28e192fda5c17250f96a2779c84475.jpg);
    }
    .back h1 {
        margin: 0;
    }
    
    /* my button style  */
    .icons-section {
        padding: 10px 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .icon-div{
            padding-right: 10px;
            .fa {
                cursor: pointer;
            }
        }
    }
}




// Css for card design