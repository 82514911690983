@import "../styles/variables";
@import "../styles/mixins";



// MEDIA QUERY START


// SCREEN SIZE > 2559px START

@media screen and (min-width: 2559px) {
    .sb-login-row {
        width: 60% !important;
        padding: 50px !important;
    }

    .col-left {
        padding: 45px 35px !important;
    }

    .sb-login-logo-div {

        padding-bottom: 20px !important;

        .sb-login-logo {
            width: 70% !important;
        }
    }

    .form-control {
        font-size: $fz20 !important;
        line-height: 35px !important;
    }

    .login-head-text {
        font-size: 40px !important;
    }

    p {
        font-size: $fz20 !important;
    }

    .sb-signin-text {
        padding-top: 5% !important;
    }

    .form-control.login-email,
    .form-control.login-password {
        margin: 35px auto !important;
        padding: 15px 25px !important;
    }

    .login-msg {
        font-size: $fz20 !important;
    }

    .forgot-pass-text {
        font-size: $fz22 !important;
    }

    .btn-login-button {
        padding: 10px 20px !important;
        font-size: $fz24 !important;
    }

    .pass-hide-show {
        font-size: $fz25 !important;
    }

    .devider {
        .v1 {
            border-left: 5px solid $primarySemiLight;
            height: 250px !important;
        }
    }

    .sb-login-bgimg {
        .lgn-moving-img {
            height: 600px !important;
        }
    }

    body {
        font-size: $fz18 !important;
    }

    .topbar {
        height: 80px !important;

        .list-unstyled {
            height: 80px !important;
        }

        .top-bar-popup {
            width: 250px !important;

            .carousel-div {
                .first-popup-div {
                    padding: 5px !important;
                }

            }
        }
    }

    .side_bar_header_main {
        padding: 20px 8px !important;
    }

    .page-wrapper .main-body {
        width: calc(100% - 235px) !important;
        padding: 100px 10px 15px 0px !important;
        transition: all 0.35s ease;
        transform: translateX(0);

        &.toggle-shift-left {
            width: calc(100% - 90px) !important;
            transition: all 0.35s ease;
            transform: translateX(0);
        }
    }

    .closemenu {
        left: 230px !important;
        font-size: $fz22 !important;
        transition: all 0.35s ease;
        transform: translateX(0);

        &.toggle-shift-left {
            left: 85px !important;
            transition: all 0.35s ease;
            transform: translateX(0);
        }
    }


    .search-row {
        .page-header-text {
            font-size: $fz20 !important;
        }
    }

    .fa-icon-div {
        width: 25px !important;
    }

    .sidebar_content {
        .expanded-nav-bar {
            .xn-text {
                margin-left: 12px !important;
                font-size: $fz20 !important;
                font-weight: 500 !important;
            }

            li {
                padding: 15px 25px 15px 15px !important;
            }
        }
    }

    .custom-table-header tr th {
        font-size: $fz18 !important;
    }

    tbody tr td {
        font-size: 1.2rem !important;
    }

    tbody .table-row-head-Text {
        font-size: 1.2rem !important;
    }

    .css-1ex1afd-MuiTableCell-root {
        font-size: 1.2rem !important;
    }

    .card-dashboard .card-body {
        min-height: 125px !important;
        max-height: 125px !important;
    }

    .material-symbols-outlined {
        font-size: 50px !important;
    }
}

// SCREEN SIZE > 2559px END

// // COMMON STYLES START
// @media screen and (min-width: 2559px) {

// }

// SCREEN SIZE > 1536px START

@media screen and (max-width: 1536px) {

    .card-head,
    .td-user-location {
        font-size: $fz14 !important;
    }

    .grap-imag {
        width: 100% !important;
    }

    .grapg-skeleton {
        height: 40px !important;
    }
}

// SCREEN SIZE > 1536px END

// SCREEN SIZE > 1366px START

@media screen and (max-width: 1366px) {
    .card-dashboard {
        .card-body {
            min-height: 80px !important;
            max-height: 80px !important;
        }
    }

    .sort-div {
        .form-control {
            &.activity-search {
                line-height: 25px;
            }
        }
    }

    .card-imag {
        width: 100% !important;
    }

    .map-element {
        height: 450px - 0 !important;
    }

    .map-conrtainer {
        min-height: 450px !important;
        max-height: 450px !important;
    }

    .user-activity-data-map {
        min-height: auto !important;
        max-height: auto !important;
    }

    .user-activity-data-col {
        min-height: 360px !important;
        max-height: 360px !important;
    }

    .sb-grap-text p,
    .client-text,
    .userlist-thead,
    .td-user-location,
    .card-head,
    .sort-left-dash,
    .selct-drop-by-dash,
    .act-heading,
    .refresh-activity,
    .select-by-dropdown,
    .sb-db-tbody td,
    .table-user-image h6 {
        font-size: 12px !important;
    }

    .user-activity-images p,
    .sb-db-table-th {
        font-size: $fz11 !important;
    }

    .sb-grap-text {
        line-height: 16px !important;
    }

    .act-num {
        font-size: $fz14 !important;
    }

    .user-list-top-head {
        padding: 8px !important;
    }

    .sch-meeting-head img,
    .direct-visit-head img {
        width: 12px !important;
        height: 12px !important;
    }

    .table-data-user-img {
        width: 40px !important;
        height: 40px !important;

    }

    .activity-icons {
        width: 23px !important;
        height: 23px !important;

        .fa,
        .fas {
            font-size: $fz13;
        }
    }

    .sb-card-title {
        font-size: 14px !important;
    }

    .custom-table-header tr th {
        font-size: $fz11 !important;
    }

    .custom-table-body tr td {
        font-size: $fz12 !important;
    }

    .sb-dashboard-table {

        .css-y8ay40-MuiTableCell-root {
            font-size: 11px;
        }
    }

    .custom-apex {
        height: 225px !important;
        min-height: 200px !important;
    }

    .custom-apex .apexcharts-canvas {
        height: 245px !important;
    }

    .user-table .react-select-container-list {
        min-width: 130px !important;
    }

    .react-select-container-list {
        min-width: 150px !important;
    }

    .grap-imag {
        width: 100% !important;
    }

    .grapg-skeleton {
        height: 30px !important;
    }
}

// SCREEN SIZE > 1366px END

// SCREEN SIZE > 1280px START

@media screen and (max-width: 1280px) {

    .form-control {
        &.create-subscription-input {
            margin: 12px auto !important;
            padding: 6px 10px !important;
            font-size: 12px !important;
        }
    }

    .sub-inputs {
        margin: 6px auto !important;

        .form-control {
            padding: 6px 10px !important;
            font-size: 12px !important;
        }

        .form-select {
            padding: 6px 10px !important;
            font-size: 12px !important;

            &.cntry-code {
                width: 35% !important;
            }
        }
    }

    .sb-login-row {
        width: 70% !important;
        padding: 20px !important;

        &.create-subscription {
            padding: 25px 30px !important;
        }
    }

    .col-left {
        padding: 15px 15px !important;
    }

    .sb-login-logo-div {
        padding-bottom: 16px !important;

        &.create-subscription {
            padding-bottom: 5px !important;
        }

        .sb-login-logo {
            width: 60% !important;

            &.metricinfo {
                width: 50% !important;
            }
        }
    }


    .add-user-input,
    .model-input {
        font-size: $fz12 !important;
        line-height: 18px !important;
        height: 30px !important;
    }

    .country-code {
        .react-select-container-list {

            .react-select-list__control {
                height: 30px !important;
            }
        }
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        height: 18px !important;
    }

    .select-add-meeting-model.meeting-date {
        font-size: $fz12 !important;
        height: 30px !important;
    }

    .model-select .react-select-list__control {
        padding: 2px 10px 6px 8px !important;
        height: 30px !important;
    }

    .float-input>label {
        font-size: 12px !important;
        bottom: 8px !important;
    }

    .float-input-visible>label,
    .float-input-visible:focus-within>label,
    .float-input:focus-within>label {
        font-size: 13px !important;
    }

    .float-visible-select>label,
    .float-visible-select:focus-within>label,
    .float-select:focus-within>label {
        font-size: 11px !important;
    }

    .add-user-input,
    .select-add-user-model,
    .model-select {
        font-size: $fz12 !important;
    }

    .country-code .react-select-container-list .react-select-list__control {
        height: 30px !important;
    }

    .login-head-text {
        font-size: 25px !important;

        &.create-subscription {
            font-size: 20px !important;
        }
    }

    p {
        font-size: $fz12 !important;
    }

    .table-div-dashboard .ord-comp-meet-claim-data .title-n-data {
        .data-title {
            font-size: $fz14 !important;
        }

        .data-value {
            font-size: $fz16 !important;
        }
    }



    .sb-signin-text {
        padding-top: 5% !important;
    }


    .login-msg {
        font-size: $fz13 !important;
    }

    .forgot-pass-text {
        font-size: $fz14 !important;
    }

    .btn-login-button {
        padding: 5px 6px !important;
        font-size: $fz14 !important;
    }

    .pass-hide-show {
        font-size: $fz18 !important;
    }

    .devider {
        .v1 {
            border-left: 5px solid $primarySemiLight;
            height: 150px !important;
        }
    }

    .sb-login-bgimg {
        .lgn-moving-img {
            height: 360px !important;
        }
    }

    .sb-grap-text p,
    .client-text,
    .userlist-thead,
    .td-user-location,
    .card-head,
    .sort-left-dash,
    .selct-drop-by-dash,
    .act-heading,
    .refresh-activity,
    .select-by-dropdown,
    .sb-db-tbody td,
    .table-user-image h6 {
        font-size: $fz11 !important;
    }

    .nav-link-item {
        min-width: 170px !important;
    }

    .page-wrapper .main-body {
        width: calc(100% - 196px) !important;
    }

    .page-wrapper .main-body.toggle-shift-left {
        width: calc(100% - 82px) !important;
    }

    .closemenu {
        left: 190px !important;
    }

    .topbar-nav .toggle-shift-left {
        left: 75px !important;
    }

    .add-single-bulk .btn-add-dropdown {
        font-size: $fz16 !important;
    }

    tbody .table-row-head-Text {
        font-size: 0.75rem !important;
    }

    .table-data-users-imgages {
        width: 35px !important;
        height: 35px !important;
    }

    tbody tr td {
        font-size: $fz12 !important;
    }

    .react-select-container-list .react-select-list__control {
        min-height: 30px !important;
        height: 32px !important;
        font-size: 12px !important;
    }

    .add-single-bulk .btn-sb-cancel {
        font-size: $fz14 !important;
    }

    .main-logo-image {
        max-height: 160px !important;
        min-height: 160px !important;
    }

    .activity-icons .fa,
    .activity-icons .fas {
        font-size: $fz15 !important;
    }


    .device-info-table {
        .device-details {
            position: relative !important;
            padding: 25px 6px !important;
        }

        .modal-update {
            margin-top: 5px !important;
            padding: 6px !important;

            .device-brand {
                font-size: 14px;
            }
        }

        .device-table {
            margin-top: 0px;
            padding: 10px 0px !important;

            .device-info-table-data {
                padding: 5px 0px !important;

                tr {
                    background-color: $lightGreen;

                    th,
                    td {
                        font-size: 12px !important;
                        padding: 4px !important;
                    }
                }
            }
        }
    }
}

// SCREEN SIZE > 1280px END


// SCREEN SIZE > 1024px START

@media screen and (max-width: 1024px) {
    .sb-login-row {
        width: 80% !important;
        padding: 20px !important;

        &.create-subscription {
            padding: 25px 30px !important;
        }
    }

    .sb-grap-text p,
    .client-text,
    .userlist-thead,
    .td-user-location,
    .card-head,
    .sort-left-dash,
    .selct-drop-by-dash,
    .act-heading,
    .refresh-activity,
    .select-by-dropdown,
    .sb-db-tbody td,
    .table-user-image h6 {
        font-size: $fz11 !important;
    }

    .grap-box {
        width: 6px !important;
        height: 6px !important;
    }

    .sb-grap-text {
        line-height: 14px !important;
    }

    .meeting-card,
    .attend-card {
        padding: 5px !important;
        margin: 0px !important;
    }

    .card-dashboard,
    .sb-data-col {
        padding: 7px 7px !important;
    }

    .card-dashboard .card-body {
        min-height: 65px !important;
        max-height: 65px !important;
    }

    .table-div-dashboard .ord-comp-meet-claim-data .title-n-data .data-title {
        font-size: 12px !important;
    }

    .table-div-dashboard .ord-comp-meet-claim-data .title-n-data .data-value,
    .table-div-dashboard .ord-comp-meet-claim-data .title-n-data .data-value i {
        font-size: 15px !important;
    }

    .table-div-dashboard .ord-comp-meet-claim-data .title-n-data {
        min-height: 65px !important;
        max-height: 65px !important;
    }

    .user-activity-data-col {
        min-height: 250px !important;
        max-height: 250px !important;
    }

    .grapg-skeleton {
        height: 30px !important;
    }

    .sort-div .form-control.activity-search {
        width: 130px !important;
        line-height: 22px !important;
    }

    .meeting-table-header {
        padding: 4px 5px 4px 5px !important;
    }

    .table-div-dashboard {
        padding: 10px !important;
    }

    .table-div-dashboard.third-row-div {
        height: 300px !important;
    }

    .user-activity-data-col .user-activity-rowdata {
        min-height: 550px !important;
        max-height: 550px !important;
    }
}

// SCREEN SIZE > 1024px END

// SCREEN SIZE > 767px START (TAB)

@media screen and (max-width: 768px) {
    .sb-login-row {
        width: 80% !important;
        padding: 30px !important;
    }

    .col-left {
        padding: 5px 5px !important;
    }

    .sb-signin-text {
        padding-top: 0px !important;
    }

    .sb-login-logo-div {
        padding-bottom: 10px !important;
    }

    .devider {
        .v1 {
            border-left: 5px solid $primarySemiLight;
            height: 100px !important;
        }
    }

    .sb-login-bgimg {
        .lgn-moving-img {
            height: 280px !important;
        }
    }

    .login-head-text {
        font-size: 20px !important;
    }

    .forgot-pass-text {
        font-size: 11px !important;
    }

    .btn-login-button {
        padding: 3px 5px !important;
        font-size: 12px !important;
        min-width: 80% !important;
        float: right !important;
    }

    .form-control.login-email,
    .form-control.login-password {
        margin: 15px auto !important;
        padding: 6px 10px !important;
    }

    .page-wrapper .main-body {
        width: calc(100% - 5px) !important;
    }

    .navbar-toggler {
        &:focus {
            box-shadow: none;
        }
    }

    // CREATE SUBSCRIPTION
        .recaptcha-part {
            .original-recaptcha {
                position: relative;
                right: 30px;
            }
        }

    // SIDEBAR & TOPBAR
    .pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
        display: none !important;
    }

    .header-item .dropdown-menu.show {
        transform: translate(-10px, 0px) !important;
    }

    .topbar {
        #navbar-custom {
            display: none !important;
        }

        li {
            list-style: none !important;
        }

        .hamburger-navbar {
            display: block;
            position: relative;
            top: -6px;
            left: 0px;

            .travelize_logo {
                width: 100%;
                height: 100%;

                .salesbee-logo {
                    width: 120px !important;
                    height: 100% !important;
                    margin: auto 10px;
                }
            }
        }

        .show-menu {
            display: block;
            position: relative;
            transition: transform 0.35s ease-out;

            .burger-menu-bg {
                position: absolute;
                top: -12px;
                left: 0px;
                width: 100%;
                opacity: 1;
                background-color: $whiteColor;
                box-shadow: $topBarShadow !important;
                height: 350px;
                overflow-x: hidden;
                transition: transform 0.35s ease-out;

                .expanded-nav-bar {
                    .sidebar-item.plain.active {
                        &::before {
                            display: none;
                        }
                    }

                    .nav-link-item.active,
                    .sidebar-item.plain.active {
                        &::before {
                            display: none;
                        }
                    }

                    .sidebar-item.open {
                        .sidebar-content {
                            &.sub-active {
                                .nav-link-item {
                                    .bi-check-square-fill {
                                        color: $primaryColor;
                                    }
                                }
                            }

                            .nav-link-item {
                                .xn-text {
                                    font-size: 13px !important;
                                }
                            }
                        }
                    }

                    .sidebar-item {
                        margin: 0px auto;

                        &.download-app-link {
                            width: 300px !important;
                            margin: 5px auto !important;
                            padding-bottom: 10px;
                        }

                        .tab-menu-items {
                            width: 250px;
                            margin: 5px auto;
                            color: #5D6064;
                        }

                        .sub-menu-items {
                            width: 250px;
                            margin: 5px auto;
                            color: #5D6064;
                        }

                        .sidebar-content {
                            .nav-link-item {
                                font-size: 13px !important;
                                padding-left: 25px;
                                color: #5D6064 !important;
                            }
                        }
                    }

                    .nav-link-item {
                        display: flex;
                        align-items: center;
                        min-width: auto !important;
                        padding: 5px;

                        .xn-text {
                            font-size: 15px !important;
                            padding-left: 10px;
                        }

                        .sidebar-title {
                            min-width: 240px;
                            margin: 5px auto;
                            color: #5D6064;

                            .xn-text {
                                font-size: 15px !important;
                                padding-left: 10px !important;
                            }

                            .toggle-btn {
                                margin-left: auto;
                                margin-right: 10px;
                            }
                        }
                    }

                }
            }
        }
    }

    .minWid180 {
        min-width: 180px !important;
    }

    // DASHBOARD 
    .material-symbols-outlined {
        font-size: 25px !important;
    }

    .claim-card, .attend-card, .company-card, .meeting-card  {
        .card-head {
            &.desk-view {
                display: none !important;
            }

            &.tab-view {
                display: block !important;

            }
        }

        .desk-view {
            display: none !important;
        }

        .tab-view {
            display: block !important;

            .sb-grap-text {
                line-height: 18px !important;

                .fz14 {
                    font-size: 14px !important;
                }
            }
        }
    }



   

    .user-activity-data-col {
        .user-activity-rowdata {
            min-height: 630px !important;
            max-height: 630px !important;
            overflow-x: hidden !important;
        }

    }

    .meeting-custom-graph {
        .apexcharts-legend {
            inset: auto 0px 20px 5px !important;
        }
    }


    .dashbord-img-box {
        .carousel-div {
            width: 50% !important;

            .first-popup-div {
                min-width: 80px !important;
            }
        }
    }

    // USER PAGE
    .react-select-container-list {
        margin: auto !important;
    }

    .filters-row {
        display: block !important;
    }

    // PRODUCTS PAGE
    .add-edit-product-model .modal-dialog {
        max-width: 70% !important;

        .col-md-4 {
            width: 100% !important;
        }

        .col-md-8 {
            width: 100% !important;
        }
    }

    .main-product-image {
        width: 50% !important;
        max-height: 100% !important;
        min-height: auto !important;
        margin: auto !important;

        .product-images {
            object-fit: contain !important;
        }
    }

    .product-box-div {
        min-height: 200px !important;
        max-height: 200px !important;
    }

    // CONNECTOR PAGE
    // Referral-Users



    // ORDERS PAGE
    .modal-backdrop{
        .modal-right-fade{
            width: 50% !important;
        }
    }

    // CLAIMS PAGE

        .view-meet-details-model, .meeter-reading-model {
            .modal-dialog {
                max-width: 650px !important;
                
            }
        }
                

    // SETTINGS PAGE

    // PROFILE PAGE
    .profile-page.profile-img{
        width: 110px !important;
        height: 110px !important;
    }

    // ACCOUNT SETTINGS

        .account-settings-page {
            height: calc(100vh - 40px) !important;
        }
         .claim-two-aprroval h6,
         .meeting-range-head h6,
         .client-mapping-setting-title h6 {
            width: 100% !important;
         }


    .sb-setting-card {
        .setting-card {
            display: block !important;

            .setting-img-card {
                margin: 5px auto !important;
            }
        }

        .setting-card-body {
            text-align: center !important;
        }
    }

        .page-content-center {
            .admin-otp-form{
                padding: 20px 18px !important;
            }
        }
}

// SCREEN SIZE > 767px END (TAB)


@media screen and (min-width : 1024px) and (max-width:1035px) {

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START
    .signIn-container-gif {
        padding: 5px 10px !important;

        img {
            width: 100% !important;
            padding: 5px 10px !important;
        }

        .text-sliding-below-img {
            max-width: 60% !important;
        }
    }

    .signin-header {
        &.login-part {
            .login-header-part {
                margin-bottom: 15px !important;
            }
        }

        &.otp-verification-part {
            .otp-header-part {
                margin-bottom: 2rem !important;
                margin-top: -100px !important;
            }
        }

        &.forgotpass-part {
            .forgotpass-header-part {
                margin-top: -10px !important;
                margin-bottom: 2rem !important;
            }
        }

        h2 {
            font-size: 1.8rem !important;
        }
    }

    .form-group {
        &.mb20 {
            margin-bottom: 20px !important;
        }
    }

    .sign-in--using-otp-div {
        margin-right: 0px !important;

        .otp-signin {
            font-size: 15px !important;
        }
    }

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START
}


@media screen and (min-width : 1440px) {

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START
    .signIn-container-gif {
        padding: 5px 10px !important;

        img {
            width: 100% !important;
            padding: 5px 10px !important;
        }
    }

    .signin-header {
        &.login-part {
            .login-header-part {
                margin-top: -100px !important;
            }
        }

        &.otp-verification-part {
            .otp-header-part {
                margin-bottom: 3rem !important;
                margin-top: -220px !important;
            }
        }

        &.signup-part {
            padding: 5px 0px !important;
        }

        &.forgotpass-part {
            .forgotpass-header-part {
                margin-top: -95px !important;
            }
        }
    }

    .form-group {
        &.mb20 {
            margin-bottom: 25px !important;
        }
    }

    .signin-button {
        &.mt5 {
            margin-bottom: 9rem !important;
        }
    }

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START
}

@media screen and (min-width : 1600px) {

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START
    .signIn-container-gif {
        margin: 5% 0px auto 4% !important;

        img {
            width: 100% !important;
        }

        .text-sliding-below-img {
            max-width: 45% !important;

            .carousel-inner {

                h6,
                p {
                    font-size: 18px !important;
                }
            }
        }
    }

    .signin-header {
        h2 {
            font-size: 2.2rem !important;
        }

        h4 {
            font-size: 1.5rem !important;
        }

        &.login-part {
            .login-header-part {
                margin-top: -130px !important;
            }
        }

        &.otp-verification-part {
            .otp-header-part {
                margin-bottom: 3rem !important;
                margin-top: -250px !important;
            }
        }

        &.forgotpass-part {
            .forgotpass-header-part {
                margin-top: -115px !important;

                p {
                    font-size: $fz16 !important;
                }
            }

            .back-to-signin {
                a {
                    font-size: $fz16 !important;
                }
            }
        }

        &.signup-part {
            padding: 5px 0px !important;

            .recaptcha-part {
                .recaptch-modal-kit {
                    transform: scale(0.9) !important;
                    margin: 10px auto !important;
                }
            }
        }
    }

    .icon-with-input {
        &.before {
            .fa {
                font-size: 20px !important;
                left: 15px !important;
            }
        }

        .pass-hide-show {
            i {
                right: 0px;
                top: 4px !important;
                font-size: 20px !important;
            }
        }
    }

    .forgot-pass-n-get-otp {
        .forget-pasword-div {
            a {
                font-weight: 500;
                font-size: $fz16 !important;
            }
        }

        .sign-in--using-otp-div {
            .otp-signin {
                font-size: $fz16 !important;
                font-weight: 500;
            }
        }
    }

    .signin-button {
        .btn-signin-button {
            font-size: 20px !important;
            line-height: 1.5 !important;
        }
    }

    .signIn-container-right-div {
        .form-control {
            border-radius: 15px !important;
            font-size: 15px !important;
            padding: 10px 45px !important;
        }
    }

    .signIn-footer {
        font-size: 18px !important;

        &.mt20 {
            margin-top: 25px !important;
        }

        a {
            font-size: 18px !important;
        }
    }

}


@media screen and (min-width : 1900px) {

    .signIn-container-gif {
        img {
            width: 90% !important;
        }
    }

    .signin-header {
        &.login-part {
            width: 70% !important;

            .login-header-part {
                margin-top: -150px !important;
            }
        }

        &.otp-verification-part {
            width: 70% !important;

            .otp-header-part {
                margin-bottom: 5rem !important;
                margin-top: -260px !important;
            }
        }

        &.forgotpass-part {
            .forgotpass-header-part {
                margin-top: -175px !important;

                p {
                    font-size: $fz16 !important;
                }
            }

            .back-to-signin {
                a {
                    font-size: $fz16 !important;
                }
            }
        }

        &.signup-part {
            .recaptcha-part {
                .recaptch-modal-kit {
                    transform: scale(1) !important;
                    margin: 15px auto !important;
                }
            }
        }
    }

    .signIn-container-right-div {
        .form-control {
            border-radius: 15px !important;
            font-size: 16px !important;
            padding: 13px 45px !important;
        }
    }

    .icon-with-select {
        &.sbefore {
            .fa {
                font-size: 18px !important;
                position: absolute !important;
                top: 16px !important;
                left: 15px !important;
            }
        }
    }

    .signup-contry-codes-div {
        .css-13cymwt-control {
            font-size: $fz16 !important;
            border-radius: 15px !important;
            padding: 7px !important;

            &:hover,
            :focus,
            :visited {
                border-radius: 15px !important;
                padding: 7px !important;
            }
        }

        .css-t3ipsp-control {
            border-radius: 15px !important;
            padding: 7px !important;
            font-size: $fz16 !important;

            &:hover {
                border-radius: 15px !important;
                padding: 7px !important;
                font-size: $fz16 !important;
            }
        }
    }
}

@media screen and (min-width : 2560px) {

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START
    .sigin-page-body {
        padding: 20px 0px !important;
    }

    .signIn-container-gif {
        img {
            width: 80% !important;
        }

        .text-sliding-below-img {
            h6 {
                font-size: 25px !important;
            }
        }
    }

    .signIn-container-right-div {
        .form-control {
            border-radius: 15px !important;
            font-size: 25px !important;
            padding: 20px 70px !important;
        }
    }

    .signin-header {
        &.login-part {
            .login-header-part {
                margin-bottom: 50px !important;

            }
        }

        &.signup-part {
            padding: 5px 0px !important;

            .recaptcha-part {
                .recaptch-modal-kit {
                    transform: scale(1) !important;
                    margin-top: 15px !important;
                }
            }
        }

        &.forgotpass-part {
            .forgotpass-header-part {
                margin-bottom: 6rem !important;

                p {
                    font-size: $fz25 !important;
                }
            }

            .forgotpass-form-data {
                margin-bottom: 175px !important;
            }
        }

        h2 {
            font-size: 4rem !important;
        }

        h4 {
            margin-bottom: 20px !important;
            font-size: 2.6rem !important;
        }

        .form-title {
            margin-bottom: 30px !important;
            font-size: 25px !important;
        }
    }

    .form-group {
        &.mb20 {
            margin-bottom: 50px !important;
        }

        &.mt3 {
            margin-top: 1.5rem !important;
        }
    }

    .signup-contry-codes-div {
        font-size: 25px !important;

        .cntry-code {
            .css-13cymwt-control {
                line-height: 1.6 !important;
                padding: 10px !important;
                border-radius: 15px !important;
                font-size: 25px !important;

                &:hover,
                :focus,
                :visited {
                    border-radius: 15px !important;
                    padding: 10px !important;
                    font-size: 25px !important;
                }
            }
        }
    }

    .source-creation {
        .react-select-list__value-container {
            padding: 5px 60px !important;
            font-size: $fz25 !important;
        }
    }

    .css-13cymwt-control {
        line-height: 1.6 !important;
        border-radius: 15px !important;
        padding: 10px !important;
        font-size: $fz25 !important;

        &:hover,
        :focus,
        :visited {
            border-radius: 15px !important;
            padding: 15px 10px !important;
            font-size: $fz25 !important;
        }
    }

    .css-t3ipsp-control {
        border-radius: 15px !important;
        padding: 12px 10px !important;

        &:hover {
            padding: 12px 10px !important;
            border-radius: 15px !important;
        }
    }

    .icon-with-input {
        &.before {
            .fa {
                font-size: 30px !important;
                left: 25px !important;
            }
        }

        .pass-hide-show {
            i {
                right: 0px;
                top: 5px !important;
                font-size: 30px !important;
            }
        }
    }

    .icon-with-select {
        &.sbefore {
            .fa {
                font-size: 30px !important;
                top: 25px !important;
                left: 25px !important;
            }
        }
    }

    .forgot-pass-n-get-otp {
        padding: 10px !important;

        .forget-pasword-div {
            a {
                font-weight: 500;
                font-size: $fz25 !important;
            }
        }

        .sign-in--using-otp-div {
            .otp-signin {
                font-size: 25px !important;
                font-weight: 500;
            }
        }
    }

    .signin-button {
        &.mb20 {
            margin-top: 50px !important;
        }

        &.mt10 {
            margin-top: 30px !important;
        }

        &.mt5 {
            margin-top: 2rem !important;
            margin-bottom: 15rem !important;
        }


        .btn-signin-button {
            font-size: 30px !important;
            line-height: 1.8 !important;

            &.signup {
                width: 60% !important;
            }
        }

        .back-to-signin {
            .fz14 {
                font-size: $fz25 !important;
            }
        }
    }

    .signIn-footer {
        font-size: 25px !important;

        &.mt20 {
            margin-top: 25px !important;
        }

        a {
            font-size: 25px !important;
        }
    }

    .back-to-signin {
        font-size: 25px !important;
    }

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START
}
