// .hierarchy-inner-container{
//     margin: auto;
//     display: flex;
//     justify-content: center;
//     position: relative;
// }
// .hierarchy-main{
//     padding: 4px;
//     border: 1px solid blue;
//     border-radius: 50%;
//     z-index: 10;
// }
// .hierarchy-branches{
//     padding: 4px;
//     border: 1px solid blue;
//     border-radius: 50%;
//     z-index: 10;
//     margin: 30px 100px;
// }
// .hierarchy-main img{
//     height: 40px;
//     width: 40px;
//     border-radius: 50%;
//     border: 5px solid blue;
//     padding: 5px;
// }
// .hierarchy-main-position{
//     position: absolute;
//     top: 40px;
// }
// .hierarchy-main-position h6{
//     // border: 1px solid;
//     border-radius: 10px;
//     margin: auto;
//     padding: 10px;
//     color: black;
//     background-color: #EDF1F2;
// }

// .hierarchy-branches-position1{
//     position: absolute;
//     left: 340px;
//     top: 70px;
// }
// .hierarchy-branch-container{
//     display: flex;
//     justify-content: center;
//     margin-top: 40px;
//     position: relative;
// }
// .hierarchy-branches img{
//     height: 40px;
//     width: 40px;
//     border-radius: 50%;
//     border: 5px solid blue;
//     padding: 5px;
// }
// .hierarchy-branches-position1 h6{
//     border-radius: 10px;
//     margin: auto;
//     padding: 10px;
//     color: black;
//     overflow: hidden;
//     background-color: #EDF1F2; 
// }
// .hierarchy-branches-position2 h6{
//     border-radius: 10px;
//     margin: auto;
//     padding: 10px;
//     color: black;
//     overflow: hidden;
//     background-color: #EDF1F2; 
// }
// .hierarchy-branches-position2{
//     position: absolute;
//     top: 70px;
// }
// .hierarchy-branches-position3 h6{
//     border-radius: 10px;
//     margin: auto;
//     padding: 10px;
//     color: black;
//     background-color: #EDF1F2; 
// }
// .hierarchy-branches-position3{
//     position: absolute;
//     top: 70px;
//     right: 338px;
// }
// .hierarchy-sub-branches img{
//     height: 40px;
//     width: 40px;
//     border-radius: 50%;
//     border: 5px solid blue;
//     padding: 5px;
//     position: relative;
// }
// .hierarchy-sub-branch-container{
//     display: flex;
//     justify-content: space-around;
// }
// .hierarchy-sub-branches1{
//     display: flex;
//     padding: 10px;
// }
// .hierarchy-sub-branch-container1{
//     border: 1px solid;
//     padding: 10px;
//     margin-top: 40px;
//     border-style: dotted;
//     border-radius: 20px;
// }
// .hierarchy-sub-branches-position1 h6{
//     padding: 10px;
// }

// .hierarchy-branch-container::before{
//     outline: solid 1px #666;
//     content: "";
//     // left: 0;
//     position: absolute;
//     right: 0;
//     top: 0;
// }
// .hierarchy-branch-container::before {left: 388px;}
// .hierarchy-branch-container::before {right: 388px;}

// .hierarchy-branches:before {
//     outline: solid 1px #666;
//     content: "";
//     height: 2.2em;
//     position: absolute;
//     top: 1px;
// }
// .hierarchy-branches:nth-child(1):before {
//     left: 388px;
// }
// .hierarchy-branches:nth-child(3):before {
//     right: 388px;
// }
// .hierarchy-branch-container .hierarchy-branches:nth-of-type(2):before {
//     left: 400px !important;
// }
// .hierarchy-sub-branch-container::before{
//     outline: solid 1px #666;
//     content: "";
//     left: 0;
//     position: absolute;
//     right: 0;
//     top: 220px;
// }

// .hierarchy-sub-branch-container::before {left: 17%;}
// .hierarchy-sub-branch-container::before {right: 17%;}

// .hierarchy-sub-branches1:before {
//     outline: solid 1px #666;
//     content: "";
//     height: 1.4em;
//     // left: 50%;
//     position: absolute;
//     top: 220px;
// }
// .hierarchy-main-position::before{
//     outline: solid 1px #666;
//     content: "";
//     height: 0.7em;
//     left: 50%;
//     position: absolute;
//     top: 40px;
// }

// .hierarchy-branches-position1::before{
//     outline: solid 1px #666;
//     content: "";
//     height: 1.6em;
//     left: 50%;
//     position: absolute;
//     top: 40px;
// }

// .hierarchy-branches-position2::before{
//     outline: solid 1px #666;
//     content: "";
//     height: 1.6em;
//     left: 50%;
//     position: absolute;
//     top: 40px;
// }

// .hierarchy-branches-position3::before{
//     outline: solid 1px #666;
//     content: "";
//     height: 1.6em;
//     left: 50%;
//     position: absolute;
//     top: 40px;
// }

// /* Move :before pseudo-element for the first element */
// .hierarchy-sub-branches1:nth-child(1):before {
//     left: 217px;
// }

// /* Move :before pseudo-element for the second element */
// .hierarchy-sub-branches1:nth-child(2):before {
//     left: 640px;
// }

// /* Move :before pseudo-element for the third element */
// .hierarchy-sub-branches1:nth-child(3):before {
//     right: 217px;
// }




/* HierarchyPage.css */

// /* Custom styles for the entire tree component */
// .org-tree {
//     background-color: #f0f0f0;
// }

// /* Custom styles for tree nodes */
// .org-tree-node {
//     background-color: #ffffff;
//     // border: 1px solid #ccc;
//     padding: 10px;
//     margin: auto;
//     // margin: 5px;
// }

// /* Custom styles for node labels */
// .org-tree-node-label {
//     color: #333;
//     font-weight: bold;
//     box-shadow: 0px 0px 6px -1.9px #a894ff;
// }

// /* Custom styles for node children container */
// .org-tree-node-children {
//     margin-left: 20px;
// }

// .hierarchy .css-19nw3og{
//     margin: auto;
// }

@import "variables";
@import "mixins";

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.initechNode {
    // border: solid 3px red;
    border-radius: 3px;
    padding: 5px;
    width: 150px;
    display: inline-block;
    position: relative;
}

#initechOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
    border-right: solid 3px $primaryColor;
}

#initechOrgChart .nodeLineBorderTop {
    border-top: solid 3px $primaryColor;
}

#initechOrgChart tbody tr {
    border-bottom: none !important;
}

#initechOrgChart tbody tr td {
    padding: 0px !important;
    border-bottom: none !important;
}

.hierarchy-image img {
    width: 40px;
    height: 40px;
    border: 5px solid $primaryColor;
    border-radius: 50%;
    padding: 5px;
}

   .hierarchy-positions {
    border-radius: 10px;
    margin: auto;
    padding: 10px;
    color: $blackColor;
    background-color: $modelhfbackgroundColor;
}
.hierarchy-div{
    overflow: auto;
    padding: 20px;
}

/* Add this to your CSS */
.hierarchy-hover-div {
    display: none; /* Initially hide the div */
    position: absolute;
    // top: 100%;
    left: 130px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    z-index: 1;
    border-radius: 3px;
    // width: 300px;
    // height: 100%;
    padding: 20px;
  }
  
  .hierarchy-hover-div.visible {
    display: block; /* Show the div when visible class is applied */
  }
  .user-email{
    display: flex;
    justify-content: flex-start;
  }
  .user-email p{
    margin-right :  8px ;
  }